// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accesibilidad-js": () => import("./../../../src/pages/accesibilidad.js" /* webpackChunkName: "component---src-pages-accesibilidad-js" */),
  "component---src-pages-building-js": () => import("./../../../src/pages/building.js" /* webpackChunkName: "component---src-pages-building-js" */),
  "component---src-pages-en-accesibility-js": () => import("./../../../src/pages/en/accesibility.js" /* webpackChunkName: "component---src-pages-en-accesibility-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-mision-js": () => import("./../../../src/pages/en/mision.js" /* webpackChunkName: "component---src-pages-en-mision-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-imms-js": () => import("./../../../src/pages/imms.js" /* webpackChunkName: "component---src-pages-imms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mision-js": () => import("./../../../src/pages/mision.js" /* webpackChunkName: "component---src-pages-mision-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

